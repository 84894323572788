import { ArrowUturnLeftIcon, QueueListIcon, RectangleStackIcon } from '@heroicons/vue/24/outline';
import { defineStore, storeToRefs } from 'pinia';
import type { NavItem } from '~~/types/NavItem';
import { useAuthStore } from '../auth';
import { useDatasTablesState } from '../datasTables';
import { TablesNames } from '~~/types/Enums';
import { useConstructionVersionsStore } from '../constructionVersions';
import { useMetreModelesStore } from '../metreModeles';

export const useNavCataloguesStore = defineStore('nav-catalogues', {
  state: () => ({
    items: computed(() => {
      const workspace = storeToRefs(useAuthStore()).workspace as Ref<any>;
      const currentVersion = storeToRefs(useConstructionVersionsStore()).currentVersion as Ref<any>;
      const currentModele = storeToRefs(useMetreModelesStore()).currentItem as Ref<any>;

      if (!workspace.value) return [];
      return [
        {
          name: 'workspace-catalogues',
          redirectToChildren: true,
          label: 'Catalogues',
          icon: RectangleStackIcon,
          params: { workspace: workspace.value?.slug },
          permission: ['view', 'workspace.catalogues'],
          children: [
            {
              name: 'workspace-catalogues-articles',
              label: 'Articles',
              params: { workspace: workspace.value?.slug },
              permission: ['view', 'workspace.catalogues.articles'],
              children: [
                {
                  name: 'workspace-catalogues-articles',
                  icon: QueueListIcon,
                  params: { workspace: workspace.value?.slug },
                  permission: ['view', 'workspace.catalogues.articles'],
                },
                {
                  name: 'workspace-catalogues-articles-types_articles',
                  label: 'Types articles',
                  params: { workspace: workspace.value?.slug },
                  permission: ['view', 'workspace.catalogues.articles.types_articles'],
                },
                {
                  name: 'workspace-catalogues-articles-gammes',
                  label: 'Gammes',
                  params: { workspace: workspace.value?.slug },
                  permission: ['view', 'workspace.catalogues.articles.gammes'],
                },

                {
                  name: 'workspace-catalogues-articles-lots',
                  label: 'Lots',
                  params: { workspace: workspace.value?.slug },
                  permission: ['view', 'workspace.catalogues.articles.lots'],
                },
              ],
            },
            {
              name: 'workspace-catalogues-metres',
              label: 'Métrés modèles',
              params: { workspace: workspace.value?.slug },
              permission: ['view', 'workspace.catalogues.metres'],
              children: currentModele.value
                ? [
                    {
                      name: 'workspace-catalogues-metres',
                      params: { workspace: workspace.value?.slug },
                      icon: ArrowUturnLeftIcon,
                      back: true,
                    },
                    {
                      label: 'Synthèse',
                      name: 'workspace-catalogues-metres-id',
                      params: { id: currentModele.value?.id, workspace: workspace.value?.slug },
                      permission: ['view', 'workspace.catalogues.metres'],
                    },
                    {
                      label: 'Paramètres',
                      params: { id: currentModele.value?.id, workspace: workspace.value?.slug },
                      name: 'workspace-catalogues-metres-id-parametres',
                      permission: ['view', 'workspace.catalogues.metres'],
                    },
                    {
                      label: 'Détails',
                      params: { id: currentModele.value?.id, workspace: workspace.value?.slug },
                      name: 'workspace-catalogues-metres-id-lignes',
                      permission: ['view', 'workspace.catalogues.metres'],
                    },
                    {
                      label: 'Options',
                      params: { id: currentModele.value?.id, workspace: workspace.value?.slug },
                      name: 'workspace-catalogues-metres-id-options',
                      permission: ['view', 'workspace.catalogues.metres'],
                    },
                  ]
                : [],
            },
            {
              name: 'workspace-catalogues-constructions',
              redirectToChildren: true,
              label: 'Constructions',
              params: { workspace: workspace.value?.slug },
              children: [
                {
                  name: 'workspace-catalogues-constructions-modeles',
                  label: 'Modèles',
                  params: { workspace: workspace.value?.slug },
                  permission: ['view', 'workspace.catalogues.constructions.modeles'],
                  children:
                    currentVersion.value?.id >= 0
                      ? [
                          {
                            params: { workspace: workspace.value?.slug },
                            icon: ArrowUturnLeftIcon,
                            back: true,
                            callback: () => {
                              const state = useDatasTablesState(TablesNames.CONSTRUCTION_MODELES);
                              state.value.nestedExpandedRows.push(currentVersion.value.modele?.id);
                              return navigateTo({
                                name: 'workspace-catalogues-constructions-modeles',
                              });
                            },
                          },
                          {
                            id: 0,
                            label: 'Infos',
                            params: { id: currentVersion.value?.id },
                            name: 'workspace-catalogues-constructions-modeles-versions-id',
                            permission: ['view', 'workspace.catalogues.constructions'],
                          },
                          {
                            id: 1,
                            label: 'Documents',
                            params: { id: currentVersion.value?.id },
                            name: 'workspace-catalogues-constructions-modeles-versions-id-documents',
                            permission: ['view', 'workspace.catalogues.constructions'],
                          },
                        ]
                      : [],
                },
                {
                  name: 'workspace-catalogues-constructions-gammes',
                  label: 'Catégories',
                  params: { workspace: workspace.value?.slug },
                  permission: ['view', 'workspace.catalogues.constructions.gammes'],
                },
              ],
            },
            {
              name: 'workspace-catalogues-terrains',
              label: 'Terrains',
              params: { workspace: workspace.value?.slug },
              permission: ['view', 'workspace.catalogues.terrains'],
              children: [],
            },
            {
              name: 'workspace-catalogues-annonces',
              label: 'Annonces',
              params: { workspace: workspace.value?.slug },
              permission: ['view', 'workspace.catalogues.annonces'],
              children: [],
            },
          ],
        },
      ];
    }) as Ref<NavItem[]>,
  }),
  getters: {},
  actions: {},
});
