import { ArrowUturnLeftIcon, FolderIcon } from '@heroicons/vue/24/outline';
import { defineStore, storeToRefs } from 'pinia';
import type { NavItem } from '~~/types/NavItem';
import { useAuthStore } from '../auth';
import { useProjetsStore } from '../projets';
import { useInformationsMarqueStore } from '../informationsMarque';
import { PrecommandeTypes, TypeConstructeur } from '~/types/Enums';
import { useCommandesStore } from '../commandes';
import type { Commande } from '~/types/models/commande';
import { useMetresStore } from '../metres';
import type { Metre } from '~/types/models/metre';

export const useNavProjetsStore = defineStore('nav-projets', {
  state: () => ({
    items: computed(() => {
      const workspace = storeToRefs(useAuthStore()).workspace as Ref<any>;
      const currentProjet = storeToRefs(useProjetsStore()).currentProjet as Ref<any>;

      const { currentMetre }: { currentMetre: Ref<Metre | null> } = storeToRefs(useMetresStore());

      const currentCommande = storeToRefs(useCommandesStore()).currentCommande as Ref<Commande>;
      const contratIsSigne = computed(() => {
        return (
          useProjetsStore().projetAvancementIs(
            currentProjet.value,
            '>=',
            useConstants.avancements.CONTRAT_SIGNE,
          ) && currentProjet.value?.contrat
        );
      });

      const { typeConstructeur } = storeToRefs(useInformationsMarqueStore());

      const canViewContrat = computed(() => !!useProjetsStore().lastAvp?.date_presentation_nd);

      const canViewPP = computed(
        () => !!useProjetsStore().lastAvp?.date_presentation_nd && !!currentProjet.value.contrat,
      );

      const projetIsValidationADV = computed(() => {
        return useProjetsStore().projetAvancementIs(
          currentProjet.value,
          '>=',
          useConstants.avancements.VALIDATION_ADV,
        );
      });

      const metreChildren = computed(() => {
        return currentMetre.value
          ? [
              {
                label: 'Métré',
                params: {
                  id: currentProjet.value?.id,
                  mid: currentMetre.value?.id,
                  workspace: workspace.value?.slug,
                },
                name: 'workspace-projets-id-avp-metre-mid-lignes',
                permission: ['view', 'workspace.projets.avps.metres.lignes'],
              },
              {
                label: 'Options',
                params: {
                  id: currentProjet.value?.id,
                  mid: currentMetre.value?.id,
                  workspace: workspace.value?.slug,
                },
                name: 'workspace-projets-id-avp-metre-mid-options',
                permission: ['view', 'workspace.projets.avps.metres.options'],
              },
            ]
          : [];
      });

      if (!workspace.value) return [];
      return [
        {
          name: 'workspace-projets',
          label: 'Projets',
          icon: FolderIcon,
          params: { workspace: workspace.value?.slug },
          permission: ['view', 'workspace.projets'],
          children: !currentProjet.value
            ? []
            : [
                {
                  name: 'workspace-projets',
                  params: { workspace: workspace.value?.slug },
                  back: true,
                  icon: ArrowUturnLeftIcon,
                  permission: ['view', 'workspace.projets'],
                },
                {
                  name: 'workspace-projets-id',
                  params: { id: currentProjet.value?.id, workspace: workspace.value?.slug },
                  label: 'Synthèse',
                  permission: ['view', 'workspace.projets'],
                },
                {
                  name: 'workspace-projets-id-avp',
                  params: { id: currentProjet.value?.id, workspace: workspace.value?.slug },
                  label: 'Avant-projet',
                  permission: ['view', 'workspace.projets.avps'],
                  children: [
                    {
                      name: 'workspace-projets-id-avp',
                      params: { id: currentProjet.value?.id, workspace: workspace.value?.slug },
                      label: 'AVP',
                      permission: ['view', 'workspace.projets.avps'],
                      children: [],
                    },
                    ...metreChildren.value,
                  ],
                },
                {
                  name: 'workspace-projets-id-contrat',
                  params: { id: currentProjet.value?.id, workspace: workspace.value?.slug },
                  label: 'Contrat',
                  permission: ['view', 'workspace.projets.contrat'],
                  condition: canViewContrat.value,
                  children: [
                    {
                      name: 'workspace-projets-id-contrat',
                      params: { id: currentProjet.value?.id, workspace: workspace.value?.slug },
                      label: 'Synthèse',
                      permission: ['view', 'workspace.projets.contrat'],
                      children: [],
                    },
                    {
                      name: 'workspace-projets-id-contrat-avenants',
                      params: { id: currentProjet.value?.id, workspace: workspace.value?.slug },
                      label: 'Avenants',
                      permission: ['view', 'workspace.projets.contrat.avenants'],
                      condition: projetIsValidationADV.value,
                    },
                    {
                      name: 'workspace-projets-id-contrat-facturation',
                      params: { id: currentProjet.value?.id, workspace: workspace.value?.slug },
                      label: 'Facturation',
                      condition: contratIsSigne.value,
                      permission: ['view', 'workspace.projets.contrat.facturation'],
                    },
                    {
                      name: 'workspace-projets-id-contrat-analytics',
                      params: { id: currentProjet.value?.id, workspace: workspace.value?.slug },
                      label: 'Analyse financière',
                      condition: contratIsSigne.value,
                      permission: ['view', 'workspace.projets.contrat.analytics'],
                    },
                  ],
                },
                {
                  name: 'workspace-projets-id-pp',
                  redirectToChildren: true,
                  params: { id: currentProjet.value?.id, workspace: workspace.value?.slug },
                  label: 'Phase préparatoire',
                  condition: canViewPP.value,
                  children: [
                    {
                      name: 'workspace-projets-id-pp-pc',
                      params: { id: currentProjet.value?.id, workspace: workspace.value?.slug },
                      label: 'PC',
                      permission: ['view', 'workspace.projets.pp.pc'],
                      condition: contratIsSigne.value,
                    },
                    {
                      name: 'workspace-projets-id-pp-financement',
                      params: { id: currentProjet.value?.id, workspace: workspace.value?.slug },
                      label: 'Financement',
                      permission: ['view', 'workspace.projets.pp.financement'],
                      condition: contratIsSigne.value,
                    },
                    {
                      name: 'workspace-projets-id-pp-terrain',
                      params: { id: currentProjet.value?.id, workspace: workspace.value?.slug },
                      label: 'Aquisition terrain',
                      permission: ['view', 'workspace.projets.pp.terrain'],
                      condition: contratIsSigne.value,
                    },
                    {
                      name: 'workspace-projets-id-pp-ci',
                      params: { id: currentProjet.value?.id, workspace: workspace.value?.slug },
                      label: 'Conception intérieure',
                      permission: ['view', 'workspace.projets.pp.ci'],
                      condition: contratIsSigne.value,
                    },
                    {
                      name: 'workspace-projets-id-pp-epe',
                      params: { id: currentProjet.value?.id, workspace: workspace.value?.slug },
                      label: 'Études & plans EXE',
                      permission: ['view', 'workspace.projets.pp.epe'],
                      condition: contratIsSigne.value,
                    },
                    {
                      name: 'workspace-projets-id-pp-garanties',
                      params: { id: currentProjet.value?.id, workspace: workspace.value?.slug },
                      label: 'Garanties',
                      permission: ['view', 'workspace.projets.pp.garanties'],
                      condition:
                        contratIsSigne.value && typeConstructeur.value === TypeConstructeur.CCMI,
                    },
                    {
                      name: 'workspace-projets-id-pp-prepaschantiers',
                      params: { id: currentProjet.value?.id, workspace: workspace.value?.slug },
                      label: 'Préparation du chantier',
                      permission: ['view', 'workspace.projets.pp.prepaschantiers'],
                      condition: contratIsSigne.value,
                    },
                  ],
                },
                {
                  name: 'workspace-projets-id-travaux',
                  redirectToChildren: true,
                  params: { id: currentProjet.value?.id, workspace: workspace.value?.slug },
                  label: 'Travaux',
                  condition: contratIsSigne.value,
                  permission: ['view', 'workspace.projets.travaux'],
                  children: [
                    {
                      name: 'workspace-projets-id-travaux-planification',
                      params: { id: currentProjet.value?.id, workspace: workspace.value?.slug },
                      permission: ['view', 'workspace.projets.travaux.planification'],
                      label: 'Planification',
                    },
                    {
                      name: 'workspace-projets-id-travaux-cmd',
                      label: 'Commandes / Marchés',
                      params: { workspace: workspace.value?.slug },
                      permission: ['view', 'workspace.projets.travaux.cmds'],
                      children:
                        currentCommande.value?.id && currentCommande.value?.id >= 0
                          ? [
                              {
                                name: 'workspace-projets-id-travaux-cmd',
                                params: { workspace: workspace.value?.slug },
                                icon: ArrowUturnLeftIcon,
                                back: true,
                                permission: ['view', 'workspace.projets.travaux.cmds'],
                              },
                              {
                                name: 'workspace-projets-id-travaux-cmd-cid',
                                label:
                                  currentCommande.value.type === PrecommandeTypes.CMD
                                    ? 'Commande'
                                    : 'Marché Travaux',
                                params: {
                                  workspace: workspace.value?.slug,
                                  cid: currentCommande.value.id,
                                },
                                permission: ['view', 'workspace.projets.travaux.cmds'],
                                children: [],
                              },
                            ]
                          : [],
                    },
                    {
                      name: 'workspace-projets-id-travaux-documents',
                      params: { id: currentProjet.value?.id, workspace: workspace.value?.slug },
                      label: 'Documents',
                      permission: ['view', 'workspace.projets.travaux.documents'],
                    },
                  ],
                },
              ],
        },
      ];
    }) as Ref<NavItem[]>,
  }),
  getters: {},
  actions: {},
});
