import { HomeIcon } from '@heroicons/vue/24/outline';
import { defineStore, storeToRefs } from 'pinia';
import type { NavItem } from '~~/types/NavItem';
import { useAuthStore } from '../auth';

import type { Workspace } from '~/types/models/workspace';

export const useNavAccueilStore = defineStore('nav-accueil', {
  state: () => ({
    items: computed(() => {
      const workspace = storeToRefs(useAuthStore()).workspace as Ref<Workspace>;
      return [
        {
          name: 'workspace-dashboard',
          label: 'Accueil',
          isRoot: true,
          icon: HomeIcon,
          params: { workspace: workspace.value?.slug },
          permission: ['view', 'workspace.dashboard'],
          children: [],
        },
      ];
    }) as Ref<NavItem[]>,
  }),
  getters: {},
  actions: {},
});
