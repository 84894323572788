import type { NavItem } from '~~/types/NavItem';

export const useFilterNavItemsByPermissions = (menu: NavItem[], permissions: any) => {
  const filteredMenu = menu.filter((menuItem) => {
    let permissionIsOk = false;
    permissionIsOk = !menuItem.permission || permissions?.can(...menuItem.permission);

    return permissionIsOk && (menuItem.condition === undefined || menuItem.condition);
  });
  filteredMenu.forEach((menuItem) => {
    if (menuItem.children) {
      menuItem.children = useFilterNavItemsByPermissions(menuItem.children, permissions);
    }
  });
  return filteredMenu;
};
