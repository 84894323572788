import { ArrowUturnLeftIcon, WrenchScrewdriverIcon } from '@heroicons/vue/24/outline';
import { defineStore, storeToRefs } from 'pinia';
import type { NavItem } from '~~/types/NavItem';
import { useAuthStore } from '../auth';
import { useMetresTravauxStore } from '../metresTravaux';
import type { MetreTravaux } from '~/types/models/metreTravaux';

export const useNavTravauxStore = defineStore('nav-travaux', {
  state: () => ({
    items: computed(() => {
      const workspace = storeToRefs(useAuthStore()).workspace as Ref<any>;
      const currentMt = storeToRefs(useMetresTravauxStore()).currentMetre as Ref<MetreTravaux>;

      if (!workspace.value) return [];
      return [
        {
          name: 'workspace-travaux',
          label: 'Travaux',
          redirectToChildren: true,
          icon: WrenchScrewdriverIcon,
          params: { workspace: workspace.value?.slug },
          permission: ['view', 'workspace.travaux'],
          children: [
            {
              name: 'workspace-travaux-mt',
              label: 'Métrés travaux',
              params: { workspace: workspace.value?.slug },
              permission: ['view', 'workspace.travaux.mt'],
              children:
                currentMt.value?.id && currentMt.value?.id >= 0
                  ? [
                      {
                        name: 'workspace-travaux-mt',
                        params: { workspace: workspace.value?.slug },
                        icon: ArrowUturnLeftIcon,
                        back: true,
                        permission: ['view', 'workspace.travaux.mt'],
                      },
                      {
                        name: 'workspace-travaux-mt-id',
                        label: 'Synthèse',
                        params: { workspace: workspace.value?.slug, id: currentMt.value.id },
                        permission: ['view', 'workspace.travaux.mt'],
                        children: [],
                      },
                      {
                        name: 'workspace-travaux-mt-id-marches',
                        label: 'Marchés',
                        params: { workspace: workspace.value?.slug, id: currentMt.value.id },
                        permission: ['view', 'workspace.travaux.mt'],
                        children: [],
                      },
                      {
                        name: 'workspace-travaux-mt-id-commandes',
                        label: 'Commandes',
                        params: { workspace: workspace.value?.slug, id: currentMt.value.id },
                        permission: ['view', 'workspace.travaux.mt'],
                        children: [],
                      },
                    ]
                  : [],
            },
            {
              name: 'workspace-travaux-suivi',
              label: 'Suivi des marchés',
              params: { workspace: workspace.value?.slug },
              permission: ['view', 'workspace.travaux.marches'],
              children: [],
            },
          ],
        },
      ];
    }) as Ref<NavItem[]>,
  }),
  getters: {},
  actions: {},
});
