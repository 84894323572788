import { DocumentIcon } from '@heroicons/vue/24/outline';
import { defineStore, storeToRefs } from 'pinia';
import type { NavItem } from '~~/types/NavItem';
import { useAuthStore } from '../auth';

export const useNavDocumentsStore = defineStore('nav-documents', {
  state: () => ({
    items: computed(() => {
      const workspace = storeToRefs<any>(useAuthStore()).workspace;
      if (!workspace.value) return [];
      return [
        {
          name: 'workspace-documents',
          label: 'Documents',
          icon: DocumentIcon,
          params: { workspace: workspace.value?.slug },
          permission: ['view', 'workspace.documents'],
        },
      ];
    }) as Ref<NavItem[]>,
  }),
  getters: {},
  actions: {},
});
