import { UsersIcon } from '@heroicons/vue/24/outline';
import { defineStore, storeToRefs } from 'pinia';
import type { NavItem } from '~~/types/NavItem';
import { useAuthStore } from '../auth';

export const useNavContactsStore = defineStore('nav-contacts', {
  state: () => ({
    items: computed(() => {
      const workspace = storeToRefs(useAuthStore()).workspace as Ref<any>;
      if (!workspace.value) return [];
      return [
        {
          name: 'workspace-contacts',
          label: 'Contacts',
          redirectToChildren: true,
          icon: UsersIcon,
          params: { workspace: workspace.value?.slug },
          permission: ['view', 'workspace.contacts'],
          children: [
            {
              name: 'workspace-contacts-clients',
              label: 'Clients',
              params: { workspace: workspace.value?.slug },
              permission: ['view', 'workspace.contacts.clients'],
              children: [],
            },
            {
              name: 'workspace-contacts-ssttfours',
              label: 'Sous-traitants / Fournisseurs',
              params: { workspace: workspace.value?.slug },
              permission: ['view', 'workspace.contacts.ssttfours'],
              children: [],
            },
          ],
        },
      ];
    }) as Ref<NavItem[]>,
  }),
  getters: {},
  actions: {},
});
