import { defineStore, storeToRefs } from 'pinia';

import type { NavItem } from '~~/types/NavItem';
import { useNavProjetsStore } from './projets';
import { useAuthStore } from '../auth';
import { useNavContactsStore } from './contacts';
import { useNavCataloguesStore } from './catalogues';
import { useNavParametresStore } from './parametres';
import { useNavTravauxStore } from './travaux';
import { useNavDocumentsStore } from './documents';
import { useGesfiStore } from './gesfi';
import { useNavAccueilStore } from './accueil';

type State = {
  items: ComputedRef<NavItem[]>;
  allowedItems: NavItem[];
  currentLevel1NavItem: NavItem;
  currentLevel2NavItem: NavItem;
  currentLevel3NavItem: NavItem;
  permissions: any;
};

const extractMatchedRoute = (matches: any[]): string[] => {
  const res = [];
  for (const match of matches) {
    if (match.name) res.push(match.name);
    else if (match.children) res.push(...extractMatchedRoute(match.children));
  }
  return res;
};

export const useNavMenuStore = defineStore('nav-menus', {
  state: () =>
    ({
      permissions: computed(() => {
        return useAuthStore().permissions;
      }),
      items: computed(() => {
        const { items: projetsItems } = storeToRefs(useNavProjetsStore());
        const { items: contactsItems } = storeToRefs(useNavContactsStore());
        const { items: cataloguesItems } = storeToRefs(useNavCataloguesStore());
        const { items: paramsItems } = storeToRefs(useNavParametresStore());
        const { items: travauxItems } = storeToRefs(useNavTravauxStore());
        const { items: documentsItems } = storeToRefs(useNavDocumentsStore());
        const { items: gesfiItems } = storeToRefs(useGesfiStore());
        const { items: accueilItems } = storeToRefs(useNavAccueilStore());

        return [
          ...accueilItems.value,
          ...projetsItems.value,
          ...contactsItems.value,
          ...travauxItems.value,
          ...gesfiItems.value,
          ...cataloguesItems.value,
          ...documentsItems.value,
          ...paramsItems.value,
        ];
      }),
    } as State),
  getters: {
    allowedItems: (state) => {
      return useAuthStore().user
        ? useFilterNavItemsByPermissions(unref(state.items), state.permissions)
        : [];
    },

    currentLevel1NavItem: (state) => {
      const route = useAppStore().currentRoute;
      const routeName = route?.name || '';

      return state.allowedItems.find((item) => {
        return (routeName as string).includes(item.name);
      });
    },

    currentLevel2NavItem: (state) => {
      const route = useAppStore().currentRoute;
      const routeName = route?.name || '';

      const values = state.currentLevel1NavItem?.children?.filter((item) => {
        return (routeName as string).includes(item.id || item.name);
      });

      if (values && values.length > 0) {
        values.sort((a, b) => {
          return b.name.split('-').length - a.name.split('-').length;
        });
        return values[0];
      }
    },

    currentLevel3NavItem: (state) => {
      const route = useAppStore().currentRoute;
      const routeName = route?.name || '';

      const values = state.currentLevel2NavItem?.children?.filter((item) => {
        return (routeName as string).includes(item.id || item.name);
      });

      if (values && values.length > 0) {
        values.sort((a, b) => {
          return b.name.split('-').length - a.name.split('-').length;
        });
        return values[0];
      }
    },

    currentLevel4NavItem: (state) => {
      const route = useAppStore().currentRoute;
      const routeName = route?.name || '';

      const values = state.currentLevel3NavItem?.children?.filter((item) => {
        return (routeName as string).includes(item.id || item.name);
      });

      if (values && values.length > 0) {
        values.sort((a, b) => {
          return b.name.split('-').length - a.name.split('-').length;
        });
        return values[0];
      }
    },
  },
  actions: {},
});
